<template>
  <common-app-page-header-v2
    :title="hideHeader ? '' : 'Communications'"
    :icon="hideHeader ? '' : 'icon-chat-messages'"
    :align-right="true"
    :padding="0"
    class="px-5 mt-5"
  >
    <template v-slot:title>
      <slot name="title" />
    </template>
    <template v-slot:primary-filter="{}">
      <div class="ml-auto d-flex align-center">
        <v-menu
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <label
              v-bind="attrs"
              class="mr-5"
              v-on="on"
            >
              <v-icon class="black--text">
                mdi-sort-variant
              </v-icon>
              Sort
            </label>
          </template>
          <v-list>
            <v-list-item
              @click="handleSort(true)"
            >
              Sort by: Date (asc)
            </v-list-item>
            <v-list-item
              @click="handleSort(false)"
            >
              Sort by: Date (desc)
            </v-list-item>
          </v-list>
        </v-menu>

        <common-input-text-field
          v-model="internalValue.request_response"
          label="Search"
          color="primary"
          clearable
          hide-details
          prepend-inner-icon="mdi-magnify"
          single-line
          class="dada"
          dense
        >
          <template v-slot:append />
        </common-input-text-field>
      </div>
    </template>
    <template v-slot:content>
      <v-col
        v-if="(hideFilters.indexOf('opportunity_id') === -1 && !hideHeader) || !hideOppFilter"
        cols="12"
        sm="4"
      >
        <common-app-entity-item-selector
          v-if="portal == PORTAL.ISSUER"
          v-model="internalValue.opportunity_id"
          color="primary"
          :label="'Opportunity'"
          :entity="'opportunities'"
          hide-details
          :item-label="'name'"
          :item-value="'id'"
          :include="'issuer'"
        />
        <common-app-entity-item-selector
          v-if="portal == PORTAL.INVESTOR"
          v-model="internalValue.opportunity_id"
          color="primary"
          :label="'Opportunity'"
          :entity="'investors/opportunities?role=fiduciary_viewer'"
          hide-details
          :item-text="'opportunity_name'"
          :use-query="false"
          :item-transform-func="oppItemTransformFunc"
        />
        <common-app-entity-item-selector
          v-if="portal == PORTAL.FID_ADMIN"
          v-model="internalValue.opportunity_id"
          color="primary"
          :label="'Opportunity'"
          :entity="'opportunities'"
          hide-details
          :item-label="'name'"
          :item-value="'opp_id'"
        />
      </v-col>
    </template>
    <template v-slot:append>
      <slot />
    </template>
  </common-app-page-header-v2>
</template>

<script>
  import { PORTAL } from '@/constants'
  export default {
    name: 'ListFilter',

    components: {
    },

    props: {
      value: {
        type: Object,
        default: () => {},
      },
      hideFilters: {
        validate: () => true,
        default: () => [],
      },
      hideHeader: {
        type: Boolean,
        default: true,
      },
      portal: {
        type: String,
        default: '',
      },
      hideOppFilter: {
        type: Boolean,
        default: true,
      },
    },

    data: () => ({
      availableKeys: [
        { key: 'sortByDate' },
        { key: 'opportunity_id' },
      ],
      PORTAL,
    }),

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    created () {
      console.log(this.portal)
    },

    methods: {
      oppItemTransformFunc (opp) {
        return {
          opportunity_name: opp.opportunity.name,
          id: opp.opportunity.opp_id,
        }
      },
      handleSort (sort) {
        this.internalValue = { ...this.internalValue, sortByDate: sort }
      },
    },
  }
</script>

<style lang="scss" scoped>
</style>
